export enum AffiliateProductStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export namespace AffiliateProductStatus {
  export function getAllAffiliateProductStatuses(): AffiliateProductStatus[] {
    return [
      AffiliateProductStatus.DRAFT,
      AffiliateProductStatus.ACTIVE,
      AffiliateProductStatus.INACTIVE,
      AffiliateProductStatus.ARCHIVED,
    ];
  }

  export function getDisplayName(status?: AffiliateProductStatus): string {
    switch (status) {
      case AffiliateProductStatus.DRAFT:
        return 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.STATUS.DRAFT';
      case AffiliateProductStatus.ACTIVE:
        return 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.STATUS.ACTIVE';
      case AffiliateProductStatus.INACTIVE:
        return 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.STATUS.INACTIVE';
      case AffiliateProductStatus.ARCHIVED:
        return 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.STATUS.ARCHIVED';
      default:
        return '';
    }
  }
}
