import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { map } from 'rxjs/operators';
import { PageListingResponse } from '@models/api/page-listing-response.model';
import { AffiliateProduct } from '@models/affiliate-products/affiliate-product.model';
import { AffiliateProductCreationRequest } from '@models/affiliate-products/dto/affiliate-product-creation.request';

@Injectable()
export class AffiliateProductsService {
  constructor(private api: ApiService) {}

  getProducts(
    filters: Map<string, string>,
    pageNumber: number = 1,
    pageSize: number = 10,
  ): Observable<PageListingResponse<AffiliateProduct>> {
    return this.api
      .getPaged(
        `${env.api}/affiliate-products/paged`,
        pageNumber,
        pageSize,
        filters,
      )
      .pipe(map((it) => this.mapAffiliateProductsPaginated(it)));
  }

  private mapAffiliateProductsPaginated(
    res: PageListingResponse<AffiliateProduct>,
  ) {
    return {
      total: res.total,
      records: res.records.map((it) => new AffiliateProduct(it)),
    };
  }

  updateProduct(
    id: number,
    body: AffiliateProductCreationRequest,
  ): Observable<AffiliateProduct> {
    return this.api
      .put(`${env.api}/affiliate-products/update/${id}`, body)
      .pipe(map((it) => new AffiliateProduct(it)));
  }

  deleteProduct(id: number): Observable<void> {
    return this.api.delete(`${env.api}/affiliate-products/delete/${id}`);
  }

  setActive(id: number, active: boolean): Observable<AffiliateProduct> {
    return this.api
      .put(`${env.api}/affiliate-products/set-active/${id}`, {
        active,
      })
      .pipe(map((it) => new AffiliateProduct(it)));
  }

  getAllAffiliateProducts(
    filters: Map<string, any>,
  ): Observable<AffiliateProduct[]> {
    if (!filters.has('forFiltering')) filters.set('forFiltering', true);
    return this.api
      .get(`${env.api}/affiliate-products`, filters)
      .pipe(map((it) => this.mapAffiliateProducts(it)));
  }

  private mapAffiliateProducts(res: AffiliateProduct[]) {
    if (!res) return [];
    return res?.map((it) => new AffiliateProduct(it));
  }

  create(
    object: AffiliateProductCreationRequest,
  ): Observable<AffiliateProduct> {
    return this.api
      .post(`${env.api}/affiliate-products/create`, object)
      .pipe(map((it) => new AffiliateProduct(it)));
  }

  getProduct(id: number): Observable<AffiliateProduct> {
    return this.api
      .get(`${env.api}/affiliate-products/${id}`)
      .pipe(map((it) => new AffiliateProduct(it)));
  }

  copy(id: number): Observable<AffiliateProduct> {
    return this.api
      .post(`${env.api}/affiliate-products/copy/${id}`, {})
      .pipe(map((it) => new AffiliateProduct(it)));
  }
}
